var titleItem 			=	['Profile', 'Message', 'Setting', 'Shopping','Credit']
var filterDataDefault			= 	{
								2:{
									mainHeading:'Custom Filters (Advanced)'
								},
								3:{
									mainHeading:'Custom Filters (Simple)'
								},
								4:{
									mainHeading:'Filtered Events'
								},
								5:{
									mainHeading:'Filtered Products'
								}
							}
var marqueeImagePath 	=	'https://advsol2020.imiscloud.com/App_Themes/GlacierBay-ASI2022-220120a/images/iMIS-logo.svg#color'
var imageCaption 		=	['Image 1', 'Image 2', 'Image 3', 'Image 4', 'Image 5', 'Image 6', 'Image 7', 'Image 8', 'Image 9', 'Image 10', 'Image 11', 'Image 12',]
var pricingTitle 		=	['FREE', 'PREMIUM', 'PLATINIUM', 'FREE']
var defaultTitle 		=	['Item 1', 'Item 2', 'Item 3', 'Item 4','Item 5','Item 6','Item 7']
var headerImages 		=	['https://api.pseudocode.com.au/imgsrc/502/', 'https://api.pseudocode.com.au/imgsrc/500/', 'https://api.pseudocode.com.au/imgsrc/506/', 'https://api.pseudocode.com.au/imgsrc/507/', 'https://api.pseudocode.com.au/imgsrc/508/',]
var galleryImages 		=	'https://api.pseudocode.com.au/imgsrc/?r='
var icons 				=	["fas fa-users", "far fa-smile", "fa fa-search", "fa fa-search"]
var cardImage 			=	['https://api.pseudocode.com.au/imgsrc/200/', 'https://api.pseudocode.com.au/imgsrc/201/', 'https://api.pseudocode.com.au/imgsrc/202/', 'https://api.pseudocode.com.au/imgsrc/203/']
var profileImage 		=	'https://api.pseudocode.com.au/imgsrc/face/?r=';
var rowClasses			=	{
								1: 'psc-cb-cardsperrow-one',
								2: 'psc-cb-cardsperrow-two',
								3: '',
								4: 'psc-cb-cardsperrow-four',
								5: 'psc-cb-cardsperrow-five',
							}
var itemObject 			=	{
								buttonLinkTabOption:3,
								icon_image:'',
								itemButtonSource: 2,
								iconImagePath:'https://api.pseudocode.com.au/imgsrc/?r=',
								iconImageKey:'',
								iconImageSource:2,
								imageSource:2,
								btnLinkPath:'',
								buttonClass:'',
								btnLinkParameters:'',
								b_imageSource:2,
								classes: 'fas fa-plus',
								isOpen: false,
								isOpenFont: false,
								colors: {
									hex: "#000000",
									source: "hex",
								},
								color: '',
								rgba: '',
								id: "",
								title: 'Profile',
								icon: "fas fa-users",
								buttonLabel: "Learn more",
								optionalHeading: "Enterprise",								
								imagePath: "",
								btnLink: "",
								twitterLink: "",
								facebookLink: "",
								googleLink: "",
								context: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
								secondContext: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
								period: "/mo",
								mainPrice: "0",
								secondMainPrice: "1",
								subItem: [],
								fontColor: '',
								b_fontColor: '',
								premium: false,
								b_title: 'Profile',
								b_optionalHeading: "Enterprise",
								b_context: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
								b_icon: "fas fa-users",
								b_buttonLabel: "Learn more",
								b_btnLink: "",
								b_sButtonLabel: "Bookmark",
								b_sBtnLink: "",
								b_color: '',
								b_imagePath: "",
								b_buttonLinkTabOption:3,
								b_itemButtonSource: 2,
								b_buttonClass:'',
								b_btnLinkParameters:'',
								imageParallex: false,
								imgSize: 1500,
								imgPosY: 0,
								imgPosX: 0,
								swapPosition: 5,
								textAlign: 4,
								imageCaption: "",
								stateicon: 'fas fa-plus'
							};

const iconClass 		= 	{small:'icon-small',medium:'icon-medium',large:'icon-large',x_large:'icon-extra-large'};

var counterItemObject 	= 	{
								selectedIqaColumn: '',
								title: 'Member',
								hardCodedCounter: 1000,
								Suffix: '+',
								counterType: 2,
								icon: "fa fa-code",
								respData: [],
								iqaDocumentVersionKey: "",
								context: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
							}

var accordionObj 		= 	{
								title: 'Profile',
								isOpen: false,
								mainItems: [],
							}

var presetSetting 		= 	{
								0: { 1: 3, 2: 3, 3: 3, 4: 3, 5: 3, 6: 3 },
								1: { 1: 3, 2: 3, 3: 3 },
								2: { 1: 1, 2: 1, 3: 1, 4: 1, 5: 4 },
								3: { 1: 3, 2: 1, 3: 3, 4: 3, 5: 3, 6: 3 },
								4: { 1: 3, 2: 2, 3: 3, 4: 1, 5: 1, 6: 3 },
								5: { 1: 1, 2: 3 },
								7: { 1: 3, 2: 3, 3: 2, 4: 3, 5: 2 },
								8: { 1: 2, 2: 3, 3: 3, 4: 4, 5: 2, 6: 3 },
								9: { 1: 3, 2: 3, 3: 3, 4: 3, 5: 3, 6: 3, 7: 3, 8: 3, 9: 3 },
								10: { 1: 3, 2: 3, 3: 3 },
								13: { 1: 3, 2: 3, 3: 3 },
								15: { 1: 10, 2: 9, 3: 11 },
								16: { 1: 3, 2: 6, 3: 6, 4: 6 },
								17: { 1: 3, 2: 3, 3: 3},
								18: { 1: 4},
								19: { 1: 4},
								20: { 1: 5, 2:5, 3:5, 4:5, 5:5, 6:6},
								21: { 1: 5,2:5},
								22: { 1: 4},
								23: { 1: 3},
							};

const getRowClasses 	= 	() => {
								return rowClasses;
							}

const getItems 			= 	(toolIndex, toolType) => {
								toolIndex = toolIndex ? toolIndex : 0;
								var accordionItems = []
								let item = [];
								if (toolIndex == 8 && [2,5].includes(toolType)) {
									for (let i = 0; i < presetSetting[toolIndex][toolType]; i++) {
										let obj = {};
										Object.assign(obj, itemObject)
										obj.premium = i == 1 ? true : false
										obj.subItem = [{ price: "0", secondPriceFlag: 1, priceItem: "item", priceStatus: true }, { price: "0", secondPriceFlag: 1, priceItem: "item", priceStatus: true }, { price: "0", secondPriceFlag: 1, priceItem: "item", priceStatus: true }, { price: "0", secondPriceFlag: 1, priceItem: "item", priceStatus: false }]
										obj.title = pricingTitle[i];
										obj.icon = icons[i];
										obj.imagePath = 'https://api.pseudocode.com.au/imgsrc/';
										item.push(obj)
									}
									let accObj = {}
									for (let j = 0; j < 2; j++) {
										Object.assign(accObj, accordionObj);
										accObj.title = titleItem[j];
										accObj.mainItems = objectData(item);
										accordionItems.push(accObj);
										accObj = {}
									}
									return accordionItems;
								}

								if (toolIndex != 12 && !(toolIndex == 8 && [2, 5].includes(toolType))) {
									for (let i = 0; i < presetSetting[toolIndex][toolType]; i++) {
										let obj = {};
										Object.assign(obj, itemObject)
										obj.premium = i == 1 ? true : false
										obj.subItem = toolIndex == 8 ? [{ price: "0", secondPriceFlag: 1, priceItem: "item", priceStatus: true }, { price: "0", secondPriceFlag: 1, priceItem: "item", priceStatus: true }, { price: "0", secondPriceFlag: 1, priceItem: "item", priceStatus: true }, { price: "0", secondPriceFlag: 1, priceItem: "item", priceStatus: false }] : []
										obj.title = [18,19,20].includes(toolIndex) ? defaultTitle[i]:toolIndex == 8 ? pricingTitle[i] : toolIndex == 15 ? imageCaption[i] : titleItem[i];
										obj.icon = icons[i];
										obj.imagePath = toolIndex == 18 ? require("@/assets/img/badge.png") : toolIndex == 19 ? marqueeImagePath :toolIndex == 15 ? galleryImages+i : toolIndex == 5 ? headerImages[i] :  toolIndex == 10 ? '' : toolIndex == 4 ? cardImage[i] : toolIndex == 8 ? 'https://api.pseudocode.com.au/imgsrc/' : profileImage+i;
										obj.b_imagePath =  toolIndex == 10 ? '' : '';
										obj.b_title = titleItem[i];
										obj.color =  (toolIndex == 10 && i == 0) ? '#d0eaf3' :(toolIndex == 10 && i == 1) ? '#edeab0':(toolIndex == 10 && i == 2) ? '#fbe6d5':'';
										obj.b_color =(toolIndex == 10 && i == 0) ? '#d0f3e2' :(toolIndex == 10 && i == 1) ? '#f1c2c2':(toolIndex == 10 && i == 2) ? '#f1c2c2':'';
										if(toolIndex == 1){
											if(i==0){
												obj['isOpen'] =true;
											}else{
												obj['isOpen'] =false;
											}
										}
										if(toolIndex == 20 && [3,4,5,6].includes(toolType)){
											obj.context='Card Title'
											obj.imagePath = 'https://api.pseudocode.com.au/imgsrc/?r=' +i
										}
										item.push(obj)
									}
								}
								return item;
							}

const getDefaultItems 	= 	(toolIndex) => {
								toolIndex = toolIndex ? toolIndex : 0;
								let itemObj = {}
								let item = [];
								for (let j = 1; j < 10; j++) {
									if ([2, 5].includes(j) && toolIndex == 8) {
										itemObj[j] = getPricingData(toolIndex, j, false, []);
									} else {
										if (presetSetting[toolIndex].hasOwnProperty(j)) {
											item = [];
											for (let i = 0; i < presetSetting[toolIndex][j]; i++) {
												let obj = {};
												Object.assign(obj, itemObject)
												obj.premium = i == 1 ? true : false
												obj.subItem = toolIndex == 8 ? [{ price: "0", secondPriceFlag: 1, priceItem: "item", priceStatus: true }, { price: "0", secondPriceFlag: 1, priceItem: "item", priceStatus: true }, { price: "0", secondPriceFlag: 1, priceItem: "item", priceStatus: true }, { price: "0", secondPriceFlag: 1, priceItem: "item", priceStatus: false }] : []
												obj.title = toolIndex == 8 ? pricingTitle[i] : toolIndex == 15 ? imageCaption[i] : titleItem[i];
												obj.icon = icons[i];
												obj.imagePath = toolIndex == 18 ? require("@/assets/img/badge.png"):toolIndex == 19 ? marqueeImagePath : toolIndex == 15 ? galleryImages+i : toolIndex == 5 ? headerImages[i] : toolIndex == 10 ? '' : toolIndex == 4 ? cardImage[i] : toolIndex == 8 ? 'https://api.pseudocode.com.au/imgsrc/' : profileImage+i;
												obj.b_imagePath =  toolIndex == 10 ? '' : '';
												obj.color =  (toolIndex == 10 && i == 0) ? '#d0eaf3' :(toolIndex == 10 && i == 1) ? '#edeab0':(toolIndex == 10 && i == 2) ? '#fbe6d5':'';
												obj.b_color =(toolIndex == 10 && i == 0) ? '#d0f3e2' :(toolIndex == 10 && i == 1) ? '#f1c2c2':(toolIndex == 10 && i == 2) ? '#f1c2c2':'';
												obj.b_title = titleItem[i];
												if(toolIndex==20 && [3,4,5,6].includes(j) ){
													obj.context='Card Title'
													obj.imagePath = 'https://api.pseudocode.com.au/imgsrc/?r=' +i
												}

												if(toolIndex == 1){
													if(i==0){
														obj['isOpen'] =true;
													}else{
														obj['isOpen'] =false;
													}
												}
												item.push(Object.assign({},obj))
											}
											itemObj[j] = item
										}
									}
								}
								return itemObj;
							}

const getConfigSetting 	= 	(toolIndex, toolType) => {
								toolIndex = toolIndex ? toolIndex : 0;
								var flagObj = {
									// global setting
									cardsPerRow:false,
									addItemButton:true,
									isControlsType:false,
									general: true,
									generalSettingText:false,
									generalSettingTextButton:false,
									generalSettingTextImage:false,
									generalMarquee: false,
									generalMarqueeSettings: false,
									generalMarqueeSettingsIqa: false,
									deleteImage:false,
									b_deleteImage:false,
									deleteMainImage:true,
									sidecontent: false,
									mainHeading: true,
									mainHeadingContext: true,
									secondMainHeadingContext: false,
									sideMainHeadingContext:false,
									mainImage: false,
									mainButtonLabel: false,
									secondaryMainButtonLabel: false,
									showIcon: true,
									swapIndex: 3,
									swapPosition: true,
									cardHeight: false,
									center: true,
									cardColor: false,
									sliderTime: false,
									imageGallery: false,
									animationDuration:false,
									embeddedLink:false,
									iqaImage:false,
									iqaLinkUrl:false,
									mainButtonClass:false,
									secondaryMainButtonClass: false,
									// item setting
									buttonLinkNewTabOption:false,
									fontColor: false,
									b_fontColor: false,
									itemsSetting: true,
									buttonLabel: false,
									buttonLink: false,
									containerColor: true,
									itemTitle: true,
									itemImageCaption: false,
									itemContext: true,
									secondItemContext:false,
									itemOptionalHeading: true,
									itemSocialIcon: false,
									itemImage: false,
									itemIconImage:false,
									itemPrice: false,
									secondMainPrice: false,
									secondPriceFlag: false,
									secondPrice: false,
									isSubItemStatus: false,
									isPremium: false,
									ispremiumImage: false,
									openTab: false,
									showFrontBackCardSetting: false,
									accordionCollapse: false,
									lineHeight: false,
									imageSize: false,
									xImagePosition: false,
									yImagePosition: false,
									imageParallex: false,
									itemSwapIndex: 3,
									itemSwapPosition: false,
									itemTextAlign: false,
									// item back side setting
									b_title:true,
									b_itemOptionalHeading: false,
									b_itemContext: false,
									b_showIcon: false,
									b_itemImage: false,
									b_cardColor: false,
									b_buttonLabel: false,
								}

							var flagSetting = {
								/* Tabs */                   0: { 1: { ...flagObj, itemOptionalHeading: false, general: false }, 2: { ...flagObj, itemOptionalHeading: false, general: false }, 3: { ...flagObj, itemOptionalHeading: false, general: false }, 4: { ...flagObj, itemOptionalHeading: false, mainHeading: false, mainHeadingContext: false, swapPosition: false, }, 5: { ...flagObj, itemOptionalHeading: false, mainHeading: false, mainHeadingContext: false, swapPosition: false, }, 6: { ...flagObj, itemOptionalHeading: false, mainHeading: false, mainHeading: false, mainHeadingContext: false, swapPosition: false, } },
								/* Accordion */              1: { 1: { ...flagObj, accordionCollapse: true, itemOptionalHeading: false, mainHeading: false, mainHeadingContext: false, swapPosition: false, }, 2: { ...flagObj, accordionCollapse: true, itemOptionalHeading: false, itemOptionalHeading: false, mainHeading: false, mainHeadingContext: false, swapPosition: false, }, 3: { ...flagObj, accordionCollapse: true, itemOptionalHeading: false, itemOptionalHeading: false, mainHeading: false, mainHeadingContext: false, swapPosition: false, } },
								/* Text */                   2: { 1: { ...flagObj, deleteMainImage:true, itemsSetting: false, showIcon: false, swapPosition: false, mainImage: true, mainButtonLabel: true, secondaryMainButtonLabel: true,mainButtonClass:true,secondaryMainButtonClass:true ,generalSettingText:true,general:false,generalSettingTextButton:true,generalSettingTextImage:true}, 2: { ...flagObj,deleteMainImage:true ,mainImage: true,itemsSetting: false, showIcon: false, center: false,generalSettingText:true,general:false,generalSettingTextButton:true,generalSettingTextImage:true }, 3: { ...flagObj, itemsSetting: false, showIcon: false, mainHeading: false, swapPosition: false,generalSettingText:true,general:false }, 4: { ...flagObj, itemsSetting: false, showIcon: false, mainHeading: true, swapPosition: false ,secondMainHeadingContext:false,mainImage: true, mainButtonLabel: true, secondaryMainButtonLabel: true,mainButtonClass:true,secondaryMainButtonClass:true,generalSettingText:true,general:false,generalSettingTextButton:true,generalSettingTextImage:true}, 5: { ...flagObj, showIcon: false.color, swapPosition: false, mainHeadingContext: false, itemOptionalHeading: false,generalSettingText:true,general:false } },
								/* Testimonial */            3: { 1: { ...flagObj,cardsPerRow:true, deleteImage:true, lineHeight: false, swapPosition: false, showIcon: false, itemImage: true, }, 2: { ...flagObj, lineHeight: false,addItemButton: false, itemOptionalHeading:false,itemTitle:false,swapPosition: false, showIcon: false, }, 3: { ...flagObj,deleteImage:true, lineHeight: false, swapPosition: false, showIcon: false, itemImage: true, }, 4: { ...flagObj, swapPosition: false, showIcon: false, }, 5: { ...flagObj, swapPosition: false, showIcon: false, }, 6: { ...flagObj, swapPosition: false, showIcon: false, } },
								/* Cards */                  4: { 1: { ...flagObj,cardsPerRow:true,deleteImage:true,buttonLinkNewTabOption:true, itemImage: true,swapPosition: false, buttonLink:true, buttonLabel: false, general: true, showIcon: false, itemOptionalHeading: false, itemTitle:true, itemContext:false }, 2: { ...flagObj,cardsPerRow:true,deleteImage:true, itemImage: true, general: true, buttonLink:true, buttonLabel: true, buttonLinkNewTabOption: true, mainHeading: true, mainHeadingContext: true, showIcon: false, swapPosition: false }, 3: { ...flagObj,cardsPerRow:true,buttonLinkNewTabOption:true, deleteImage:true,lineHeight: false, itemImage: true, buttonLink:true, buttonLabel: true, buttonLinkNewTabOption:true, general: true, mainHeading: true, mainHeadingContext: true, swapPosition: false, showIcon: false }, 4: { ...flagObj,deleteImage:true,buttonLinkNewTabOption:true, itemImage: true, buttonLink:true, buttonLabel: true, buttonLinkNewTabOption:true, general: true,mainHeading: true, mainHeadingContext: true, swapPosition: false, showIcon: false }, 5: { ...flagObj,buttonLinkNewTabOption:true, deleteImage:true,itemImage: true, buttonLink:true, buttonLabel: true, buttonLinkNewTabOption:true, general: true, mainHeading: true, mainHeadingContext: true, swapPosition: false, showIcon: false}, 6: { ...flagObj,cardsPerRow:true,buttonLinkNewTabOption:true,deleteImage:true, itemImage: true, buttonLink:true, buttonLabel: true, buttonLinkNewTabOption:true, general: true, mainHeading: true, mainHeadingContext: true, swapPosition: false, showIcon: false } },
								/* Banners */                5: { 1: { ...flagObj,buttonLinkNewTabOption:true,addItemButton: false,deleteImage:true,fontColor:true, general: true, itemsSetting: true, itemTextAlign: true, itemImage: true, itemOptionalHeading: false, lineHeight: false, imageSize: true, xImagePosition: true, yImagePosition: true, imageParallex: true, cardHeight: true, mainHeadingContext: false, mainHeading: false, swapPosition: false, showIcon: false, lineHeight: false, itemSwapPosition: true, buttonLink:true, buttonLabel: true, buttonLinkNewTabOption: true }, 2: { ...flagObj,buttonLinkNewTabOption:true,deleteImage:true,fontColor:true, general: true, itemsSetting: true, itemTextAlign: true, itemOptionalHeading: false, itemImage: true, lineHeight: false, imageSize: true, xImagePosition: true, yImagePosition: true, imageParallex: false, cardHeight: true, mainHeadingContext: false, mainHeading: false, swapPosition: false, showIcon: false, lineHeight: false, itemSwapPosition: true, sliderTime: true, buttonLink:true, buttonLabel: true, buttonLinkNewTabOption: true } },
								/* Teams */                  7: { 1: { ...flagObj,cardsPerRow:true,deleteImage:true, lineHeight: false, itemSocialIcon: true, itemImage: true, showIcon: false, swapPosition: false }, 2: { ...flagObj,cardsPerRow:true,deleteImage:true, lineHeight: false, itemSocialIcon: true, itemImage: true, showIcon: false, swapPosition: false }, 3: { ...flagObj,deleteImage:true,cardsPerRow:true, lineHeight: false, itemSocialIcon: true, itemImage: true, showIcon: false, swapPosition: false }, 4: { ...flagObj,cardsPerRow:true,deleteImage:true,addItemButton: false, lineHeight: false, itemSocialIcon: true, itemImage: true, showIcon: false, swapPosition: false }, 5: { ...flagObj,cardsPerRow:true, deleteImage:true,addItemButton: false,lineHeight: false, itemSocialIcon: true, itemImage: true, showIcon: false, swapPosition: false } },
								/* Pricing */                8: { 1: { ...flagObj, ispremiumImage: true, isPremium: true, buttonLink:true, buttonLabel: true, buttonLinkNewTabOption:true, showIcon: false, swapPosition: false }, 2: { ...flagObj, ispremiumImage: true, isPremium: true, secondMainPrice: true, itemPrice: true, buttonLink:false, buttonLabel: false, showIcon: false, swapPosition: false, mainHeadingContext: false, itemContext: false, itemOptionalHeading: false }, 3: { ...flagObj, ispremiumImage: true, isPremium: true, itemPrice: true, buttonLink:true, buttonLabel: true, buttonLinkNewTabOption:true, showIcon: false, swapPosition: false, itemOptionalHeading: false, itemContext: false }, 4: { ...flagObj, ispremiumImage: true, isPremium: true, isSubItemStatus: true, itemPrice: true, buttonLink:true, buttonLabel: true, buttonLinkNewTabOption:true, showIcon: false, swapPosition: false, itemOptionalHeading: false, itemContext: false }, 5: { ...flagObj, ispremiumImage: true, isPremium: true, secondPriceFlag: true, secondMainPrice: true, itemPrice: true, buttonLink:false, buttonLabel: false, buttonLinkNewTabOption:true, showIcon: false, swapPosition: false, itemOptionalHeading: false, itemContext: false }, 6: { ...flagObj, ispremiumImage: true, isPremium: true, itemPrice: true, buttonLink:true, buttonLabel: true, buttonLinkNewTabOption:true, showIcon: false, swapPosition: false, itemOptionalHeading: false, itemContext: false } },
								/* Features */               9: { 1: { ...flagObj,cardsPerRow:true,buttonLinkNewTabOption:true, lineHeight: false, itemOptionalHeading: false, buttonLink:true, buttonLabel: true, buttonLinkNewTabOption:true, cardColor: false }, 2: { ...flagObj,cardsPerRow:true,buttonLinkNewTabOption:true, lineHeight: false, itemOptionalHeading: false, buttonLink:true, buttonLabel: true,buttonLinkNewTabOption:true, cardColor: false }, 3: { ...flagObj,cardsPerRow:true, buttonLinkNewTabOption:true,lineHeight: false, itemOptionalHeading: false, buttonLink:true, buttonLabel: true, buttonLinkNewTabOption:true, cardColor: false }, 4: { ...flagObj, deleteMainImage:true, lineHeight: false, itemOptionalHeading: false, center: false, mainHeadingContext: false, mainImage: true }, 5: { ...flagObj,deleteMainImage:true, lineHeight: false, itemOptionalHeading: false, center: false, swapIndex: 5, mainHeadingContext: false, mainImage: true }, 6: { ...flagObj,cardsPerRow:true,buttonLinkNewTabOption:true, lineHeight: false, itemOptionalHeading: false, buttonLink:true, buttonLabel: true,buttonLinkNewTabOption:true, cardColor: false }, 7: { ...flagObj,cardsPerRow:true,buttonLinkNewTabOption:true, lineHeight: false, itemOptionalHeading: false, buttonLink:true, buttonLabel: true, buttonLinkNewTabOption:true, cardColor: false }, 8: { ...flagObj,cardsPerRow:true,buttonLinkNewTabOption:true, lineHeight: false, itemOptionalHeading: false, buttonLink:true, buttonLabel: true, buttonLinkNewTabOption:true, cardColor: false }, 9: { ...flagObj,cardsPerRow:true,buttonLinkNewTabOption:true, lineHeight: false, itemOptionalHeading: false, buttonLink:true, buttonLabel: true, buttonLinkNewTabOption:true, cardColor: false } },
								/* Flipbox */               10: { 1: { ...flagObj,cardsPerRow:true,b_deleteImage:true, deleteImage:true,swapPosition: false, lineHeight: false, b_buttonLabel: true, b_itemImage: true, b_cardColor: true, cardColor: true, b_itemOptionalHeading: true, b_itemContext: true, showIcon: true, mainHeading: false, mainHeadingContext: false, swapPosition: false, itemImage: true, showFrontBackCardSetting: true,fontColor:true,b_fontColor: true }, 2: { ...flagObj,cardsPerRow:true,b_deleteImage:true, deleteImage:true, swapPosition: false, lineHeight: false, b_buttonLabel: false, b_itemImage: true, b_cardColor: true, cardColor: true, b_itemOptionalHeading: true, b_itemContext: true, showIcon: true, mainHeading: false, mainHeadingContext: false, swapPosition: false, itemImage: true, showFrontBackCardSetting: true ,fontColor: true,b_fontColor: true}, 3: { ...flagObj,cardsPerRow:true,b_deleteImage:true,itemContext:false,itemOptionalHeading:false, deleteImage:true,swapPosition: false, lineHeight: false, b_buttonLabel: true, b_itemImage: true, b_cardColor: true, cardColor: true, b_itemOptionalHeading: false,b_title:false, b_itemContext: true, showIcon: false, mainHeading: true, mainHeadingContext: true, swapPosition: false, itemImage: true, showFrontBackCardSetting: true,fontColor:true,b_fontColor: true,itemIconImage:true }, },
								/* event */                 13: { 1: { ...flagObj, itemImage: true, buttonLink:true, buttonLabel: true,buttonLinkNewTabOption:true, general: false }, 2: { ...flagObj, itemImage: true, buttonLink:true, buttonLabel: true, buttonLinkNewTabOption:true, mainHeading: false, mainHeadingContext: false, swapPosition: false }, 3: { ...flagObj, lineHeight: false, itemImage: true, buttonLink:true, buttonLabel: true, buttonLinkNewTabOption:true, general: true, mainHeading: false, mainHeadingContext: false, swapPosition: false, showIcon: false }, 4: { ...flagObj, itemImage: true, buttonLink:true, buttonLabel: true, buttonLinkNewTabOption:true, general: false }, 5: { ...flagObj, itemImage: true, buttonLink:true, buttonLabel: true, buttonLinkNewTabOption:true, general: false }, 6: { ...flagObj, itemImage: true, buttonLink:true, buttonLabel: true, buttonLinkNewTabOption:true, general: false } },
								/* Image Gallery */         15: { 1: { ...flagObj, itemImageCaption: true, itemOptionalHeading: false, mainHeading: false, general: false, mainImage: true, itemTitle: false, itemContext: false, showIcon: false, itemImage: true }, 2: { ...flagObj, imageGallery: true, itemImageCaption: true, itemOptionalHeading: false, mainHeading: true, general: true, swapPosition: false,showIcon: false, mainImage: false, itemTitle: false, itemContext: false, showIcon: false, itemImage: true }, 3: { ...flagObj, itemImageCaption: true, itemOptionalHeading: false, mainHeading: false, general: false, mainImage: true, itemTitle: false, itemContext: false, showIcon: false, itemImage: true }, 4: { ...flagObj, itemOptionalHeading: false, mainHeading: false, mainHeadingContext: false, swapPosition: false, }, 5: { ...flagObj, itemOptionalHeading: false, mainHeading: false, mainHeadingContext: false, swapPosition: false, }, 6: { ...flagObj, itemOptionalHeading: false, mainHeading: false, mainHeading: false, mainHeadingContext: false, swapPosition: false, } },
								/* Counter */               16: { 1: { ...flagObj, itemOptionalHeading: false, general: false, mainHeading: true, mainHeadingContext: true, itemContext: false }, 2: { ...flagObj, itemOptionalHeading: false, general: false, mainHeading: true, mainHeadingContext: false, itemContext: false }, 3: { ...flagObj, itemOptionalHeading: false, general: false, itemTitle: false, showIcon: false }, 4: { ...flagObj, itemOptionalHeading: false, mainHeading: false, mainHeadingContext: false, swapPosition: false, }, 5: { ...flagObj, itemOptionalHeading: false, mainHeading: false, mainHeadingContext: false, swapPosition: false, }, 6: { ...flagObj, itemOptionalHeading: false, mainHeading: false, mainHeading: false, mainHeadingContext: false, swapPosition: false, } },
								/* */                       17: { 1: { ...flagObj, general: true, mainHeading: true, itemOptionalHeading: false, mainHeadingContext: true, mainImage: true, itemTitle: false, itemContext: false, showIcon: false, itemImage: true }, 2: { ...flagObj, imageGallery: true, itemImageCaption: true, itemOptionalHeading: false, mainHeading: true, general: true, swapPosition: false,showIcon: false, mainImage: false, itemTitle: false, itemContext: false, showIcon: false, itemImage: true }, 3: { ...flagObj, itemImageCaption: true, itemOptionalHeading: false, mainHeading: false, general: false, mainImage: true, itemTitle: false, itemContext: false, showIcon: false, itemImage: true }, 4: { ...flagObj, itemOptionalHeading: false, mainHeading: false, mainHeadingContext: false, swapPosition: false, }, 5: { ...flagObj, itemOptionalHeading: false, mainHeading: false, mainHeadingContext: false, swapPosition: false, }, 6: { ...flagObj, itemOptionalHeading: false, mainHeading: false, mainHeading: false, mainHeadingContext: false, swapPosition: false, } },
								/* Badges */                18: { 1: { ...flagObj, general: true, mainHeading: true,buttonLinkNewTabOption:true, itemOptionalHeading: false, mainHeadingContext: true, mainImage: false, itemTitle: false, itemContext: false, showIcon: false, itemImage: true, swapPosition: false, buttonLink:true, buttonLabel: false }},
								/* Marquee */               19: { 1: { ...flagObj, general: true, mainHeading: true, buttonLinkNewTabOption:true, itemOptionalHeading: false, mainHeadingContext: true, mainImage: false, itemTitle: false, itemContext: false, showIcon: false, itemImage: true, swapPosition: false,animationDuration: true , buttonLink:true, buttonLabel: false }},
								/* Perspective carousel */  20: { 1: { ...flagObj,isControlsType:true,deleteImage:true, cardHeight: true, general: true,sidecontent:true,sideMainHeadingContext:true, mainHeading: true, itemOptionalHeading: false, mainHeadingContext: true, mainImage: false, itemTitle: false, itemContext: true, showIcon: false, itemImage: true, swapPosition: false }, 3: { ...flagObj,isControlsType:true,deleteImage:true, cardHeight: true, general: true,sidecontent:true,sideMainHeadingContext:true, mainHeading: true, itemOptionalHeading: false, mainHeadingContext: true, mainImage: false, itemTitle: false, itemContext: true, showIcon: false, itemImage: true, swapPosition: false }, 4: { ...flagObj,isControlsType:true,deleteImage:true, cardHeight: true, general: true,sidecontent:true,sideMainHeadingContext:true, mainHeading: true, itemOptionalHeading: false, mainHeadingContext: true, mainImage: false, itemTitle: false, itemContext: true, showIcon: false, itemImage: true, swapPosition: false }, 2: { ...flagObj,isControlsType:true, cardHeight: true,deleteImage:true, general: true,sidecontent:false,sideMainHeadingContext:true, mainHeading: true, itemOptionalHeading: false, mainHeadingContext: true, mainImage: false, itemTitle: false, itemContext: true, showIcon: false, itemImage: true, swapPosition: false }, 5: { ...flagObj,isControlsType:true, cardHeight: true,deleteImage:true, general: true,sidecontent:false,sideMainHeadingContext:true, mainHeading: true, itemOptionalHeading: false, mainHeadingContext: true, mainImage: false, itemTitle: false, itemContext: true, showIcon: false, itemImage: true, swapPosition: false }, 6: { ...flagObj,isControlsType:true, cardHeight: true,deleteImage:true, general: true,sidecontent:false,sideMainHeadingContext:true, mainHeading: true, itemOptionalHeading: false, mainHeadingContext: true, mainImage: false, itemTitle: false, itemContext: true, showIcon: false, itemImage: true, swapPosition: false }, },
								/* Embedded Video */  		21: { 1: { ...flagObj,embeddedLink:true, general: true, mainHeading: true, itemOptionalHeading: false, mainHeadingContext: true, swapPosition:false, itemsSetting: false },},
								/* Dynamic Marquee */       22: { 1: { ...flagObj, general: false, itemsSetting:false, mainHeading: true, buttonLinkNewTabOption:true, itemOptionalHeading: false, mainHeadingContext: true, mainImage: false, itemTitle: false, itemContext: false, showIcon: false, itemImage: true, swapPosition: false,animationDuration: true , buttonLink:true, buttonLabel: false,iqaImage:true,iqaLinkUrl:true,generalMarquee:true,generalMarqueeSettings:true,generalMarqueeSettingsIqa:true }},
								/* Overlays */		        23: { 1: { ...flagObj,cardsPerRow:true,secondItemContext:true,swapPosition: false, general: true, showIcon: false, itemOptionalHeading: false, itemTitle:true, itemContext:true },},

							};
							return flagSetting[toolIndex][toolType];
							}

const addItem 			= 	(data) => {
								if (data && data.toolIndex == 8 && [2, 5].includes(data.toolType)) {
									return getPricingData(8, data.toolType, true, data)
								} else {
									itemObject.subItem = [];
									if (data.toolIndex == 8) {
										itemObject.subItem.push({ price: "0", priceItem: "item", priceStatus: true }, { price: "0", priceItem: "item", priceStatus: true }, { price: "0", priceItem: "item", priceStatus: true }, { price: "0", priceItem: "item", priceStatus: false });
									}
									return ({ ...itemObject, title: "Heading" + data.copyAdd, imagePath:data.toolIndex == 19 ? marqueeImagePath :data.toolIndex == 10 ? '' : data.toolIndex == 4 ? cardImage[0] : profileImage+'0' })
								}
							}

const addPriceCardItem 	= 	(data, title) => {
								itemObject.subItem = [];
								itemObject.subItem.push({ price: "0", priceItem: "item", priceStatus: true });
								return ({ ...itemObject, title: "Heading" + title, imagePath: data.toolIndex == 4 ? cardImage[0] : profileImage+'0' })
							}

const copyData 			= 	(data, index) => {
								var subItemArray = []
								if (data && data.toolIndex == 8 && [2, 5].includes(data.toolType)) {
									let obj = {
										title: "Copy" + data.copyAdd,
										isOpen: false,
										mainItems: copyPricingData(data, index)
									}
									return obj
								} else {
									if (data.items[index].hasOwnProperty('subItem')) {
										data.items[index]["subItem"].forEach(element => {
											var obj = {}
											Object.assign(obj, element)
											subItemArray.push(obj);
										});
									}

									return ({
										title: "Copy" + data.copyAdd,
										icon: data.items[index]["icon"],
										context: data.items[index]["context"],
										secondItemContext: data.items[index]["secondItemContext"],
										buttonLabel: data.items[index]["buttonLabel"],
										optionalHeading: data.items[index]["optionalHeading"],
										image: data.items[index]["image"],
										imagePath: data.items[index]["imagePath"],
										imageKey: data.items[index]["imageKey"],
										subItem: subItemArray,
										b_title: "Copy" + data.copyAdd,
										b_optionalHeading: data.items[index]["b_optionalHeading"],
										b_context: data.items[index]["b_context"],
										b_icon: data.items[index]["b_icon"],
										b_buttonLabel: data.items[index]["b_buttonLabel"],
										b_imagePath: data.items[index]["b_imagePath"],
									});
								}
							}

const copyCounterData 	= 	(data, index) => {
							return ({
								title: "Copy" + data.copyAdd,
								icon: data.items[index]["icon"],
								context: data.items[index]["context"],
								counterType: data.items[index]["counterType"],
								hardCodedCounter: data.items[index]["hardCodedCounter"],
								Suffix: data.items[index]["Suffix"],
								iqaDocumentVersionKey: data.items[index]["iqaDocumentVersionKey"],
								respData: data.items[index]["respData"],
								selectedIqaColumn: data.items[index]["selectedIqaColumn"],     
							});
							}

const addCounterItem 	= 	(data) => {
							return ({
								...counterItemObject,
								title: "Copy" + data.copyAdd,    
							});
							return ({ ...itemObject, title: "Heading" + data.copyAdd, imagePath: data.toolIndex == 10 ? '' : data.toolIndex == 4 ? cardImage[0] : profileImage+'0' })
							}

function copyPricingData(data, index){
	let obj = {}
	let arr = []
	let dataArr = data.items[index]['mainItems']
	dataArr.forEach(element => {
		obj = {}
		Object.assign(obj, element)
		arr.push(obj)
	});
	return arr
}

function objectData(data){
    let obj = {}
    let arr = []
    data.forEach(element => {
        obj = {}
        Object.assign(obj, element)
        obj.subItem = [];
        obj.subItem.push({ price: "0", secondPriceFlag: 1, priceItem: "item", priceStatus: true }, { price: "0", secondPriceFlag: 1, priceItem: "item", priceStatus: true }, { price: "0", secondPriceFlag: 1, priceItem: "item", priceStatus: true }, { price: "0", secondPriceFlag: 1, priceItem: "item", priceStatus: false });
        arr.push(obj)
    });
    return arr;
}

function getPricingData(toolIndex, toolType, flag, data){
    var accordionItems = []
    let item = [];
    for (let i = 0; i < presetSetting[toolIndex][toolType]; i++) {
        let obj = {};
        Object.assign(obj, itemObject)
        obj.premium = i == 1 ? true : false
        obj.subItem = toolIndex == 8 ? [{ price: "0", secondPriceFlag: 1, priceItem: "item", priceStatus: true }, { price: "0", secondPriceFlag: 1, priceItem: "item", priceStatus: true }, { price: "0", secondPriceFlag: 1, priceItem: "item", priceStatus: true }, { price: "0", secondPriceFlag: 1, priceItem: "item", priceStatus: false }] : []
        obj.title = toolIndex == 8 ? pricingTitle[i] : titleItem[i];
        obj.icon = icons[i];
        obj.imagePath = toolIndex == 4 ? cardImage[i] : toolIndex == 8 ? 'https://api.pseudocode.com.au/imgsrc/' : profileImage+i;
        item.push(obj)
    }
    let accObj = {}
    for (let j = 0; j < 2; j++) {
        item[0].subItem = [];
        item[0].subItem.push({ price: "0", secondPriceFlag: 1, priceItem: "item", priceStatus: true }, { price: "0", secondPriceFlag: 1, priceItem: "item", priceStatus: true }, { price: "0", secondPriceFlag: 1, priceItem: "item", priceStatus: true }, { price: "0", secondPriceFlag: 1, priceItem: "item", priceStatus: false });
        Object.assign(accObj, accordionObj);
        accObj.title = titleItem[j];
        accObj.mainItems = item;
        if (flag) {
            accObj.title = "Heading" + data.copyAdd
            return accObj
        }
        accordionItems.push(accObj);
        accObj = {}
    }
    return accordionItems;
}

const getCounterItems 	= 	(toolIndex, toolType) => {
								toolIndex = toolIndex ? toolIndex : 0;
								let item = [];
								for (let i = 0; i < presetSetting[toolIndex][toolType]; i++) {
									let obj = {};
									Object.assign(obj, counterItemObject)
									obj.title = i % 2 == 0 ? 'Member' : 'Non-Member';
									item.push(obj)
								}
								return item;
							}

const getDefaultCounterItems = (toolIndex) => {
								toolIndex = toolIndex ? toolIndex : 0;
								let itemObj = {}
								let item = [];
								for (let j = 1; j < 10; j++) {
									if (presetSetting[toolIndex].hasOwnProperty(j)) {
										item = [];
										for (let i = 0; i < presetSetting[toolIndex][j]; i++) {
											let obj = {};
											Object.assign(obj, counterItemObject)
											obj.title = i % 2 == 0 ? 'Member' : 'Member';
											item.push(obj)
										}
										itemObj[j] = item
									}
								}
								return itemObj;
							}
const getClassName		 = 	()=>{ return iconClass; }

const getLabelName		=	()=>{
							
								var labelName ={
									general: 'Global',
									settings: 'Settings',
									iqa: 'IQA',
									mainHeading :'Heading',
									cardHeight: 'Card Height',
									mainContent :'Introduction',
									heading: 'Title',
									subHading:'Sub-Title',
									icon: 'Icon',
									content: 'Content',
									imageTitle:'Image Title',
									swapPosition:'Text Position',
									showIcon:'Show Icon',
									sideContent:'Side Content',
									buttonLink:'Button Link',
									imageCaption:'Image',
									imageSource:'Image Source',
									itemButtonSource:'Button Source',
									iconImageCaption:'Icon Image',
								}

								var labelSetting = {
									/* Tabs */                   0: {...labelName,general:'Global Icon Settings',heading:'Tab Label',icon:'Tab Icon',content:'Tab Content', showIcon:'Show Label Icon'},
									/* Accordion */              1: {...labelName,heading:'Label',icon:'Label Icon',content:'Content'},
									/* Text */                   2: {...labelName,mainHeading:'Title',mainContent:'Content'},
									/* Testimonial */            3: {...labelName,heading:"Person's Name",subHading:'Job Position/Company',content:'Quote'},
									/* Cards */                  4: {...labelName,heading:'Card Title',subHading:'Card Sub-Title',content:'Card Content'},
									/* Banners */                5: {...labelName,heading:'Heading',content:'Text',cardHeight:'Banner Height'},
									/* Teams */                  7: {...labelName,heading:'Name',subHading:'Job Title/Position',content:'Short Bio',},
									/* Pricing */                8: {...labelName,},
									/* Features */               9: {...labelName,swapPosition:'Text Align',heading:'Feature Title',icon:'Feature Icon',content:'Feature Description'},
									/* Flipbox */               10: {...labelName,imageSource:'Background Image Source',imageCaption:'Background Image',heading:'Title',subHading:'Sub-Title',icon:'Sub-Title Icon',content:'Content'},
									/* event */                 13: {...labelName,},
									/* Image Gallery */         15: {...labelName, heading:'Image Title'},
									/* Counter */               16: {...labelName,},
									/* */                       17: {...labelName,},
									/* Badges */                18: {...labelName,buttonLink:'Image Link'},
									/* Marquee */               19: {...labelName,buttonLink:'Image Link'},
									/* Perspective carousel */  20: {...labelName,content:'Card Content'},
									/* Embedded Video */  		21: {...labelName,},
									/* Dynamic Marquee */       22: {...labelName,buttonLink:'Image Link'},
									/* Overlays */       		23: {...labelName,heading:'Title',content:'Full Description',secondContent:'Short Description'},

								};
								return labelSetting;
							}

const getDefaultConfigLabel		=	(index)=>{
								
								var labelName ={
									mainHeading :'Follow your dreams',
									mainHeadingContext :"We’re constantly trying to express ourselves and actualize our dreams. If you have the opportunity to play this game of life you need to appreciate every moment",
									sideMainHeadingContext :"We’re constantly trying to express ourselves and actualize our dreams. If you have the opportunity to play this game of life you need to appreciate every moment",
									secondMainHeadingContext :"We’re constantly trying to express ourselves and actualize our dreams. If you have the opportunity to play this game of life you need to appreciate every moment",
									mainButtonLabel:'Button Link',
									secondaryMainButtonLabel: 'Button Link',
								}

								var labelSetting = {
									/* Tabs */                   0: {...labelName,},
									/* Accordion */              1: {...labelName,},
									/* Text */                   2: {...labelName,mainButtonLabel:'Primary Button Label',secondaryMainButtonLabel:'Secondary Button'},
									/* Testimonial */            3: {...labelName,},
									/* Cards */                  4: {...labelName,},
									/* Banners */                5: {...labelName,mainButtonLabel:"Invest Now"},
									/* Teams */                  7: {...labelName,},
									/* Pricing */                8: {...labelName,},
									/* Features */               9: {...labelName,},
									/* Flipbox */               10: {...labelName,},
									/* event */                 13: {...labelName,},
									/* Image Gallery */         15: {...labelName,},
									/* Counter */               16: {...labelName,},
									/* */                       17: {...labelName,},
									/* Badges */                18: {...labelName,},
									/* Marquee */               19: {...labelName,},
									/* Perspective carousel */  20: {...labelName,},
									/* Embedded Video */  		21: {...labelName,},
									/* Dynamic Marquee */       22: {...labelName,},
									/* Overlays */     			23: {...labelName,},

								};
								return labelSetting[index];
							}

const getItemsDefaultConfigLabel		=	(index,preset)=>{
								
								var labelName ={
									title :'Profile',
									buttonLabel:'Read More',
									optionalHeading:'ENTERPRISE',
									icon:'fas fa-users',
									btnLinkPath:'',
									buttonClass:'',
									btnLinkParameters:'',
									imagePath:'https://api.pseudocode.com.au/imgsrc/506/',
									image:require("@/assets/img/badge.png"),
									context : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
									secondContext : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
								}

								var labelSetting = {
									/* Tabs */                   0: { 1: {...labelName,imagePath:'https://api.pseudocode.com.au/imgsrc/506/'},2: {...labelName,imagePath:'https://api.pseudocode.com.au/imgsrc/507/',icon:'far fa-smile',title:'Message'},3: {...labelName,imagePath:'https://api.pseudocode.com.au/imgsrc/508/',icon:'fa fa-search',title:'Setting'}},
									/* Accordion */              1: { 1: {...labelName,imagePath:'https://api.pseudocode.com.au/imgsrc/506/'},2: {...labelName,imagePath:'https://api.pseudocode.com.au/imgsrc/507/',icon:'far fa-smile',title:'Message'},3: {...labelName,imagePath:'https://api.pseudocode.com.au/imgsrc/508/',icon:'fa fa-search',title:'Setting'}},
									/* Text */                   2: { 1: {...labelName,imagePath:'https://api.pseudocode.com.au/imgsrc/?r=1'},2: {...labelName,imagePath:'https://api.pseudocode.com.au/imgsrc/?r=2',icon:'far fa-smile',title:'Message'},3: {...labelName,imagePath:'https://api.pseudocode.com.au/imgsrc/?r=3',icon:'fa fa-search',title:'Setting'}},
									/* Testimonial */            3: { 1: {...labelName,imagePath:'https://api.pseudocode.com.au/imgsrc/face/?r=1'},2: {...labelName,imagePath:'https://api.pseudocode.com.au/imgsrc/face/?r=2',icon:'far fa-smile',title:'Message'},3: {...labelName,imagePath:'https://api.pseudocode.com.au/imgsrc/face/?r=3',icon:'fa fa-search',title:'Setting'}},
									/* Cards */                  4: { 1: {...labelName,imagePath:'https://api.pseudocode.com.au/imgsrc/200/'},2: {...labelName,imagePath:'https://api.pseudocode.com.au/imgsrc/201/',icon:'far fa-smile',title:'Message'},3: {...labelName,imagePath:'https://api.pseudocode.com.au/imgsrc/202/',icon:'fa fa-search',title:'Setting'}},
									/* Banners */                5: { 1: {...labelName,imagePath:'https://api.pseudocode.com.au/imgsrc/506/'},2: {...labelName,imagePath:'https://api.pseudocode.com.au/imgsrc/507/',icon:'far fa-smile',title:'Message'},3: {...labelName,imagePath:'https://api.pseudocode.com.au/imgsrc/508/',icon:'fa fa-search',title:'Setting'}},
									/* Teams */                  7: { 1: {...labelName,imagePath:'https://api.pseudocode.com.au/imgsrc/506/'},2: {...labelName,imagePath:'https://api.pseudocode.com.au/imgsrc/507/',icon:'far fa-smile',title:'Message'},3: {...labelName,imagePath:'https://api.pseudocode.com.au/imgsrc/508/',icon:'fa fa-search',title:'Setting'}},
									/* Pricing */                8: { 1: {...labelName,imagePath:'https://api.pseudocode.com.au/imgsrc/506/'},2: {...labelName,imagePath:'https://api.pseudocode.com.au/imgsrc/507/',icon:'far fa-smile',title:'Message'},3: {...labelName,imagePath:'https://api.pseudocode.com.au/imgsrc/508/',icon:'fa fa-search',title:'Setting'}},
									/* Features */               9: { 1: {...labelName,imagePath:'https://api.pseudocode.com.au/imgsrc/506/'},2: {...labelName,imagePath:'https://api.pseudocode.com.au/imgsrc/507/',icon:'far fa-smile',title:'Message'},3: {...labelName,imagePath:'https://api.pseudocode.com.au/imgsrc/508/',icon:'fa fa-search',title:'Setting'}},
									/* Flipbox */               10: { 1: {...labelName,imagePath:'https://api.pseudocode.com.au/imgsrc/506/'},2: {...labelName,imagePath:'https://api.pseudocode.com.au/imgsrc/507/',icon:'far fa-smile',title:'Message'},3: {...labelName,imagePath:'https://api.pseudocode.com.au/imgsrc/508/',icon:'fa fa-search',title:'Setting'}},
									/* event */                 13: { 1: {...labelName,imagePath:'https://api.pseudocode.com.au/imgsrc/506/'},2: {...labelName,imagePath:'https://api.pseudocode.com.au/imgsrc/507/',icon:'far fa-smile',title:'Message'},3: {...labelName,imagePath:'https://api.pseudocode.com.au/imgsrc/508/',icon:'fa fa-search',title:'Setting'}},
									/* Image Gallery */         15: { 1: {...labelName,imagePath:'https://api.pseudocode.com.au/imgsrc/506/'},2: {...labelName,imagePath:'https://api.pseudocode.com.au/imgsrc/507/',icon:'far fa-smile',title:'Message'},3: {...labelName,imagePath:'https://api.pseudocode.com.au/imgsrc/508/',icon:'fa fa-search',title:'Setting'}},
									/* */                       17: { 1: {...labelName,imagePath:'https://api.pseudocode.com.au/imgsrc/506/'},2: {...labelName,imagePath:'https://api.pseudocode.com.au/imgsrc/507/',icon:'far fa-smile',title:'Message'},3: {...labelName,imagePath:'https://api.pseudocode.com.au/imgsrc/508/',icon:'fa fa-search',title:'Setting'}},
									/* Badges */                18: { 1: {...labelName,imagePath:'https://api.pseudocode.com.au/imgsrc/506/'},2: {...labelName,imagePath:'https://api.pseudocode.com.au/imgsrc/507/',icon:'far fa-smile',title:'Message'},3: {...labelName,imagePath:'https://api.pseudocode.com.au/imgsrc/508/',icon:'fa fa-search',title:'Setting'}},
									/* Perspective carousel */  20: { 1: {...labelName,imagePath:'https://api.pseudocode.com.au/imgsrc/face/?r=1'},2: {...labelName,imagePath:'https://api.pseudocode.com.au/imgsrc/face/?r=2',icon:'far fa-smile',title:'Message'},3: {...labelName,context:'Card Title',imagePath:'https://api.pseudocode.com.au/imgsrc/face/?r=3',icon:'fa fa-search',title:'Setting'},4: {...labelName,context:'Card Title',imagePath:'https://api.pseudocode.com.au/imgsrc/face/?r=4',icon:'fas fa-users',title:'Users'},5: {...labelName,context:'Card Title',imagePath:'https://api.pseudocode.com.au/imgsrc/face/?r=5',icon:'far fa-smile',title:'Message'},6: {...labelName,context:'Card Title',imagePath:'https://api.pseudocode.com.au/imgsrc/face/?r=5',icon:'far fa-smile',title:'Message'}},
									/* Embedded Video */  		21: { 1: {...labelName,imagePath:'https://api.pseudocode.com.au/imgsrc/506/'},2: {...labelName,imagePath:'https://api.pseudocode.com.au/imgsrc/507/',icon:'far fa-smile',title:'Message'},3: {...labelName,imagePath:'https://api.pseudocode.com.au/imgsrc/508/',icon:'fa fa-search',title:'Setting'}},
									/* Marquee */               19: { 1: {...labelName,imagePath:'https://api.pseudocode.com.au/imgsrc/506/',image:'https://advsol2020.imiscloud.com/App_Themes/GlacierBay-ASI2022-220120a/images/iMIS-logo.svg#color'},2: {...labelName,imagePath:'https://api.pseudocode.com.au/imgsrc/507/',icon:'far fa-smile',title:'Message',image:'https://advsol2020.imiscloud.com/App_Themes/GlacierBay-ASI2022-220120a/images/iMIS-logo.svg#color'},3: {...labelName,imagePath:'https://api.pseudocode.com.au/imgsrc/508/',icon:'fa fa-search',title:'Setting',image:'https://advsol2020.imiscloud.com/App_Themes/GlacierBay-ASI2022-220120a/images/iMIS-logo.svg#color'}},
									/* Dynamic Marquee */       22: { 1: {...labelName,imagePath:'https://api.pseudocode.com.au/imgsrc/506/',image:'https://advsol2020.imiscloud.com/App_Themes/GlacierBay-ASI2022-220120a/images/iMIS-logo.svg#color'},2: {...labelName,imagePath:'https://api.pseudocode.com.au/imgsrc/507/',icon:'far fa-smile',title:'Message',image:'https://advsol2020.imiscloud.com/App_Themes/GlacierBay-ASI2022-220120a/images/iMIS-logo.svg#color'},3: {...labelName,imagePath:'https://api.pseudocode.com.au/imgsrc/508/',icon:'fa fa-search',title:'Setting',image:'https://advsol2020.imiscloud.com/App_Themes/GlacierBay-ASI2022-220120a/images/iMIS-logo.svg#color'}},
									/* Counter */               16: { 1: {...labelName,title:'Member',context:'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'},2: {...labelName,title:'Non-Member',context:'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'},3: {...labelName,title:'Member',context:'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'},4: {...labelName,title:'Non-Member',context:'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'},5: {...labelName,title:'Member',context:'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'},6: {...labelName,title:'Non-Member',context:'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'}},
									/* Overlays */ 			    23: { 1: {...labelName,}},

								};
								return labelSetting[index][preset];
							}

const getConfigItems = (index,count)=>{
								let temp = []
								for (let preset = 1; preset <= count; preset++) {
									let tempObj = {}
									tempObj = Object.assign({},
										{
											colors: { hex: "#000000", source: "hex", },
											isOpen: "",
											color: "",
											rgba: "",
											buttonLabel: getItemsDefaultConfigLabel(index,preset).buttonLabel,
											optionalHeading: getItemsDefaultConfigLabel(index,preset).optionalHeading,
											title: getItemsDefaultConfigLabel(index,preset).title,
											icon: getItemsDefaultConfigLabel(index,preset).icon,
											imagePath: getItemsDefaultConfigLabel(index,preset).imagePath,
											image: getItemsDefaultConfigLabel(index,preset).image,
											imageKey: "",
											context: getItemsDefaultConfigLabel(index,preset).context,
											secondContext: getItemsDefaultConfigLabel(index,preset).secondContext,
										},
										)
									temp.push()	
								}
								return temp;
							}


const getCommonConfig		=	(index, settings,count=3)=>{
	
							var	configForm = {
								openTab: settings.openTab || 0,
								showIcon: (settings && settings.showIcon) || settings.showIcon == 0 ? settings.showIcon : 1,
								iconPostion: settings.iconPostion || 0,
								iconSize: settings.iconSize || "medium",
								mainImageKey: settings.mainImageKey || "",
								cardHeight: settings.cardHeight || 350,
								controlsType: settings.controlsType || 2,
								mainImagePath:settings && settings.hasOwnProperty('mainImagePath')? settings.mainImagePath: 'https://api.pseudocode.com.au/imgsrc/?r=1',
								mainBtnLinkPath:settings.mainBtnLinkPath?settings.mainBtnLinkPath:'',
								mainSecondaryBtnLinkPath:settings.mainSecondaryBtnLinkPath?settings.mainSecondaryBtnLinkPath:'',
								website: settings && settings.website ? settings.website : "",
								themeSelected: settings && settings.themeSelected ? settings.themeSelected : "",   
								cardsPerRow:settings.cardsPerRow?settings.cardsPerRow: 3,
								mainButtonLabel: settings && settings.mainButtonLabel ? settings.mainButtonLabel : getDefaultConfigLabel(index).mainButtonLabel,
								secondaryMainButtonLabel: settings.secondaryMainButtonLabel?settings.secondaryMainButtonLabel: getDefaultConfigLabel(index).secondaryMainButtonLabel,
								swapPosition: settings && settings.swapPosition ? settings.swapPosition : "",
								showIcon: (settings && settings.showIcon) || settings.showIcon == 0 ? settings.showIcon : 1,
								iconPostion: settings.iconPostion || 0,
								mainHeading: settings && settings.hasOwnProperty('mainHeading') ? settings.mainHeading : getDefaultConfigLabel(index).mainHeading,
								mainHeadingContext: settings && settings.hasOwnProperty('mainHeadingContext') ? settings.mainHeadingContext : getDefaultConfigLabel(index).mainHeadingContext,
								sideMainHeadingContext: settings && settings.hasOwnProperty('sideMainHeadingContext') ? settings.sideMainHeadingContext : getDefaultConfigLabel(index).sideMainHeadingContext,
								items: settings && settings.items ? settings.items : getConfigItems(index,count),
								toolType: settings && settings.toolType ? settings.toolType : "",
								toolIndex: index,
								rowSetting: settings && settings.rowSetting ? settings.rowSetting
									: {
										spacing: {
										margin: { t: 0, r: 0, b: 0, l: 0, ratio: "px", },
										padding: { t: 0, r: 0, b: 0, l: 0, ratio: "px", },
										},
										id:'',
										themes: { primaryColor: "red", classes: "", },
										blockBtnLink:'',
										blockBtnLinkPath:'',
										blockBtnLinkParameters:'',
										blockLabel:'Block Link Label',
										externalThemeCss:'',
										animation:'',
        								inlineThemeCss:'',
										background: {
										backGroundCol: "",
										imageParallex: false,
										selectBackground: 0,
										mainColor: "",
										contBackGroundCol: "rgba(255, 255, 255, 0)",
										backgroundStyle: 0,
										imagePath: "",
										imageKey: "",
										textCol: "",
										tabCol: "",
										opacity: 50,
										},
									},
								}

								return configForm;
							}

const getDefaultFilterData = ()=>{
								return filterDataDefault
								}

export default {
    getItems,
    getDefaultItems,
    getConfigSetting,
    addItem,
    copyData,
    copyCounterData,
    addCounterItem,
    addPriceCardItem,
    getDefaultCounterItems,
    getCounterItems,
    getClassName,
    getLabelName,
	getDefaultConfigLabel,
	getItemsDefaultConfigLabel,
	getCommonConfig,
	getRowClasses,
	getDefaultFilterData,
}