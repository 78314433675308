<template>
    <imis-panel-field v-slot="slot"
        :caption="caption"
        :positionCaption="positionCaption"
        :size="size"
        :info="info"
        :autoWidth="autoWidth"
        :disabled="disabled"
        :required="required"
        :valid="valid"
        :error="error" >
        <div :class="slot.size">
            <ckeditor 
              :id="slot.uid"
              :disabled="disabled"
              :editor="editor"               
              :config="editorConfig"
              v-bind="$attrs"
              v-on="$listeners">
              <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"><slot :name="slot" v-bind="scope"/></template>
            </ckeditor>
        </div>
    </imis-panel-field>
</template>

<script>
import ImisPanelField from './ImisPanelField.vue'

import CKEditor from '@ckeditor/ckeditor5-vue2';
import ClassicEditor from '@ckeditor/ckeditor5-build-imis';

export default {
  name: 'ImisCkEditor',
  components: {
    ImisPanelField,
    ckeditor: CKEditor.component
  },
  props: {
    disabled: Boolean,
    caption: String,
    positionCaption: String,
    size: String,
    info: String,
    autoWidth: Boolean,
    required: Boolean,
    valid: Boolean,
    error: String,
    config: {
      type: Object, 
      default () { 
        return {}
    }},
  mode:String,
  },
  data () {
    return {
      editor: ClassicEditor,
      editorConfig: {        
        ...this.config,
        iMIS: {
          imageManager: this.imageManagerDialog,
          contentLinkManager: this.linkManagerDialog,
          downloadLinkManager: this.downloadManagerDialog 
        }
      }
    }
  }, 
  methods: {
    downloadManagerDialog(editor) {            
      let urlParams = 'DocumentPath=%24%2FCommon%2FUploaded%20files&iRootFolder=%24%2FCommon%2FUploaded%20files&ShowMenu=true&ShowMenuPublish=false&ShowMenuVersions=false&ShowMenuRun=false&ShowMenuPreview=false&ShowMenuNew=true&ShowRecycleBin=false'
      window.OpenObjectBrowser(urlParams, (sender, args) => {
        return this.downloadManagerCallback(sender, args, editor)
      }, true, false, null, 'Select your content item')
    },
    downloadManagerCallback (sender, args, editor) {      
      if (!(args.get_argument() !== null && args.get_argument().cancel) && sender.SelectedDocumentPath !== undefined) {
        let href = ''
        if (this.mode === '2017') {
          href = window.gWebRoot + '/download.aspx?DocumentVersionKey=' + sender.result               
        } else {
          href = '/Common/' + sender.SelectedDocumentPath
        }           
        if (editor?.model?.document?.selection?.isCollapsed) {
          editor.model.change(writer => {
            const link = writer.createText(sender.SelectedDocumentName, {            
              linkHref: href
            })          
            editor.model.insertContent(link, editor.model.document.selection)
          })
        } else {
          editor.execute('link', href)
        }
      }              
    },
    linkManagerDialog(editor) {      
      let urlParams = 'DocumentPath=%40&iRootFolder=%40&TypeFilter=CON&ShowRecycleBin=false'
      window.OpenObjectBrowser(urlParams, (sender, args) => {
        return this.linkManagerCallback(sender, args, editor)
      }, true, false, null, 'Select your content item')
    },
    linkManagerCallback (sender, args, editor) {
      if (!(args.get_argument() !== null && args.get_argument().cancel) && sender.SelectedDocumentPath !== undefined) {
        let href = window.gWebRoot + (sender.SelectedDocumentPath[0] === '@' ? sender.SelectedDocumentPath.substring(1) : sender.SelectedDocumentPath) + '.aspx'
        editor.execute('link', href)
      }              
    },
    imageManagerDialog(editor) {        
      const selection = editor.model.document.selection.getSelectedElement()    
      const src = selection !== null ? selection.getAttribute('src') : ''
      const urlParam = src !== '' ? '?PreselectedItemUrl=' + encodeURIComponent(src) : ''   
      if (this.mode === '2017') {
        window.ShowDialog('~/ClientSideImageManager.aspx' + urlParam, null, null, null, 'Image Manager', null, 'E', (sender, args) => {
          return this.imageManagerCallback(sender, args, editor)
        })        
      } else {
        let urlParams = 'DocumentPath=%24%2FImages&iRootFolder=%24%2FImages&ShowMenu=true&ShowMenuPublish=false&ShowMenuVersions=false&ShowMenuRun=false&ShowMenuPreview=false&ShowMenuNew=true&ShowRecycleBin=false'                
        window.OpenObjectBrowser(urlParams, (sender, args) => {
          return this.imageManagerCallback(sender, args, editor)
        }, true, false, null, 'Select an image')            
      }        
    },
    imageManagerCallback (sender, args, editor) {  
      let imageUrl = ''  
      if (this.mode === '2017') {
        if (args.get_argument() !== null) {  
          imageUrl = (new URL(args?.get_argument()?.get_value()[0]?.src))?.pathname
        }
      } else {
        if (!(args.get_argument() !== null && args.get_argument().cancel) && sender.SelectedDocumentPath !== undefined) {
          imageUrl = '/' + sender.SelectedDocumentPath
        }
      }      
      if (imageUrl !== '') {
        editor.model.change(writer => {
          const imageElement = writer.createElement( 'imageBlock', {
            src: imageUrl
          })          
          editor.model.insertContent(imageElement, editor.model.document.selection)
        })          
      }    
    }
  }  
}
</script>

