// import mtapi from './core'
/*
class objectBrowserDialog {
  constructor () {
    this.options = {
      windowTitle: 'Document Browser',
      documentPath
    }
  }
}
*/
const openObjectBrowserDialog = (
    documentPath = '@/',
    rootFolder = '',
    typeFilter = '',
    windowTitle = 'Document Browser',
    multiSelect = false,
    publishedOnly = false,
    options = {
        showRecycleBin: false,
        hideAddress: false,
        showMenu: false,
        showMenuPublish: false,
        showMenuVersions: false,
        showMenuRun: false,
        showMenuPreview: false,
        hideMenuNew: false,
        hideMenuEdit: false,
        hideMenuOrganize: false
    }
) => {

}

export default {
    openObjectBrowserDialog
}

/*

        windowTitle: {type:String, default:'Document Browser'},
        documentPath: {type:String, default:'@/'},
        rootFolder: {type:String, default:''},
        typeFilter: {type: String, default:''},
        multiSelect: {type:Boolean, default: false},
        publishedOnly: {type:Boolean, default: false},
        showRecycleBin: {type:Boolean, default: false},
        hideAddress: {type:Boolean, default: false},
        showMenu: {type:Boolean, default: false},
        showMenuPublish: {type:Boolean, default: false},
        showMenuVersions: {type:Boolean, default: false},
        showMenuRun: {type:Boolean, default: false},
        showMenuPreview: {type:Boolean, default: false},
        hideMenuNew: {type:Boolean, default: false},
        hideMenuEdit: {type:Boolean, default: false},
        hideMenuOrganize: {type:Boolean, default: false}
    },
    data () {
        return {
            path: ''
        }
    },
    computed: {
        urlParams () {
            let urlParamString = `DocumentPath=${encodeURIComponent(this.documentPath)}`
            if (this.rootFolder !== '')
                urlParamString += `&iRootFolder=${encodeURIComponent(this.rootFolder)}`
            if (this.typeFilter !== '')
                urlParamString += `&TypeFilter=${encodeURIComponent(this.typeFilter.toUpperCase())}`
            urlParamString += `&ShowRecycleBin=${this.showRecycleBin}`
            if (this.hideAddress)
                urlParamString += `&ShowAddress=${!this.hideAddress}`
            if (this.showMenu)
                urlParamString += `&ShowMenu=${this.showMenu}`
            if (this.showMenu) {
                urlParamString += `&ShowMenuPublish=${this.showMenuPublish}`
                urlParamString += `&ShowMenuVersions=${this.showMenuVersions}`
                urlParamString += `&ShowMenuRun=${this.showMenuRun}`
                urlParamString += `&ShowMenuPreview=${this.showMenuPreview}`
                urlParamString += `&ShowMenuNew=${!this.hideMenuNew}`
                urlParamString += `&ShowMenuEdit=${!this.hideMenuEdit}`
                urlParamString += `&ShowMenuOrganize=${!this.hideMenuOrganize}`
            }
            return urlParamString
*/