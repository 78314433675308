<template>
  <div>
     
    <div
      v-for="(item, index) in collapse"
      :key="item"
      :class="[
        'md-collapse',
        activeCollapse(index + 1),
        { [getColorCollapse(colorCollapse)]: true }
      ]"
    >
      <div
        class="md-collapse-label"
        @click="toggle(index + 1)"
      >
        <h5 class="md-collapse-title">
            

            <span  v-if="isActive == index+1 && !Settings.collapse_icon_position">
            
            <i   aria-hidden="true" :class="expandIcon" ></i>
           </span>
           <span  v-if="isActive != index+1  && !Settings.collapse_icon_position">
            
            <i   aria-hidden="true" :class="collapsedIcon" ></i>
           </span>
            
                
                <span v-if="Settings.collapse_icon_position">
                   
                  <i :class="Settings.items[index].icon" v-if="Settings.showIcon" :style="{'font-size': Settings.iconSize}"></i>
                </span>
          {{ item }} 
           <span class=" float-right" v-if="isActive == index+1  && Settings.collapse_icon_position">
            
            <i   aria-hidden="true" :class="expandIcon" ></i>
           </span>
           <span class=" float-right" v-if="isActive != index+1  && Settings.collapse_icon_position">
            
            <i   aria-hidden="true" :class="collapsedIcon" ></i>
           </span>
          <!-- <md-icon :class="item.expandIcon"></md-icon> -->
        </h5>
      </div>

      <collapse-transition :duration="350">
        <div v-show="getActiveCollapse(index + 1)" class="md-collapse-content">
          <slot :name="getCollapseContent(index + 1)" />
        </div>
      </collapse-transition>
    </div>
  </div>
</template>

<script>
import { CollapseTransition } from "vue2-transitions";

export default {
  name: "None",
  components: {
    CollapseTransition
  },
  props: {
    zIndex:Number,
    collapse: Array,
    titleIcons: Array,
    icon: String,
    colorCollapse: String,
    activeTab: Number,
    expandIcon: String,
    collapsedIcon: String,
    Settings: Object
  },
  data() {
    return {
      isActive: this.activeTab
    };
  },
  methods: {
    getActiveCollapse(index) {
     
      return this.isActive == index;
    },
    activeCollapse(index) {
     
      return {'is-active':this.isActive == index}
      
    },
    toggle(index) {
     
      if (index == this.isActive) {
        this.isActive = 0;
      } else {
        this.isActive = index;
      }
    },
    getCollapseContent: function(index) {
      
      return "md-collapse-pane-" + index + "";
    },
    getColorCollapse: function(colorCollapse) {
      
      return "md-" + colorCollapse + "";
    },
    deletePrice(i){
     
     let data = {i:this.zIndex,j:i}
      this.$emit('deletePrice', data)
    }
  }
};
</script>

<style lang="scss" scoped>
.text-center {
  display: flex;
}
</style>

<style lang="scss" >

.app .someVariable .md-collapse-label .md-collapse-title {
    color: var(--someVariable)!important;
}
  
</style>