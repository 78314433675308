<template>
	<div class="psc-cb-card" :class="{'psc-cb-card-horizontal':type=='horizontal','psc-cb-card-vertical':type!='horizontal'}">
		<template>
			<div
				class="psc-cb-card-header psc-cb-card-image"
				:class="carousel?'mx-3':''"
				:shadow-normal="shadowNormal"
				:no-colored-shadow="noColoredShadow"
			>
				<a :target="cardTarget" :href="cardUrl" class="psc-cb-card-imagelink" :style="'background-image: url('+cardImage+');'"></a>
				<div v-if="$slots.cardTitleHeader" class="psc-cb-card-title"><a :href="cardUrl"><slot name="cardTitleHeader" /></a></div>
			</div>
			<div class="psc-cb-card-detailcol" :class="{ 'reverse-row-grid': reverseRow }">
				<div :class="carousel?'mx-3':''" v-if="$slots.cardContent" class="psc-cb-card-content"><slot name="cardContent" /></div>
				<div :class="carousel?'mx-3':''" v-if="$slots.cardAction" class="psc-cb-card-actions"><slot name="cardAction" /></div>
			</div>
		</template>
	</div>
</template>

<script>
export default {
  name: "BlogCard",
  props: {
    carousel:Boolean,
    type: String,
    cardUrl : String,
    reverseRow: Boolean,
    toolType:Number,
    indexNo:Number,
    cardImage: String,
    shadowNormal: {
      type: Boolean,
      default: false
    },
    cardPlain: Boolean,
    noColoredShadow: {
      type: Boolean,
      default: false
    },
    cardTarget: String,
  },
  methods: {
    shadowImageBlog(image) {
      // return  {
      //   backgroundImage: `url(${image})`,
      //   opacity: 1
      // };
    }
  }
};
</script>
