<template>
    <div class=" someVariable">
        <div class="accordion" id="accordion2" v-if="Settings.expand == 2">
            <div class="accordion-group" v-for="(item, i) of configForm.toolType ? Settings.items : defaultItem" :key="i">
                <div class="accordion-heading">
                    <a class="accordion-toggle" data-toggle="collapse" :href="'#expandAll' + i" aria-expanded="true">
                        <span v-if="!Settings.collapse_icon_position" class="closed">
                            <i :class="Settings.expandIcon"></i>
                        </span>
                        <span v-if="!Settings.collapse_icon_position" class="opened">
                            <i :class="Settings.collapseIcon"></i>
                        </span>
                        <span v-if="Settings.collapse_icon_position">
                            <i :class="item.icon" v-if="Settings.showIcon" :style="{'font-size': Settings.iconSize}"></i>
                        </span>
                        {{ item.title }}
                        <span v-if="Settings.collapse_icon_position" class="closed float-right">
                            <i :class="Settings.expandIcon"></i>
                        </span>
                        <span v-if="Settings.collapse_icon_position" class="opened float-right">
                            <i :class="Settings.collapseIcon"></i>
                        </span>
                    </a>
                </div>
                <div :id="'expandAll' + i" class="accordion-body collapse in">
                    <div class="accordion-inner" v-html="item.context"></div>
                </div>
            </div>
        </div>


        <div v-if="Settings.expand == 0 && Settings.collapse_inactive == 0">
            <nones :active-tab="0" :collapse="getCollapseTitles(configForm.toolType ? Settings.items : defaultItem)" :expandIcon="Settings.expandIcon" :collapsedIcon="Settings.collapseIcon" :Settings="Settings" icon="keyboard_arrow_down" color-collapse="success">
                <template :slot="'md-collapse-pane-' + (i + 1)"
                    v-for="(item, i) of configForm.toolType ? Settings.items : defaultItem">
                    <p :key="i" v-html="item.context"></p>
                </template>
            </nones>
        </div>

        <div class="panel-group" id="accordionExpandAll" v-if="Settings.expand == 1 && Settings.collapse_inactive == 1">
            <div v-for="(item, i) of configForm.toolType ? Settings.items : defaultItem" :key="i">
                <div class="panel panel-primary" v-if="i == 0">
                    <div class="panel-heading">
                        <div class="panel-title">
                            <a class="accordion-toggle" data-toggle="collapse" data-parent="#accordionExpandAll"
                                :href="'#firstOpen' + i" aria-expanded="true">
                                <span v-if="!Settings.collapse_icon_position" class="closed">
                                    <i :class="Settings.expandIcon"></i>
                                </span>
                                <span v-if="!Settings.collapse_icon_position" class="opened">
                                    <i :class="Settings.collapseIcon"></i>
                                </span>
                                <span v-if="Settings.collapse_icon_position">
                                    <i :class="item.icon"></i>
                                </span>
                                {{ item.title }}
                                <span v-if="Settings.collapse_icon_position" class="closed float-right">
                                    <i :class="Settings.expandIcon"></i>
                                </span>
                                <span v-if="Settings.collapse_icon_position" class="opened float-right">
                                    <i :class="Settings.collapseIcon"></i>
                                </span>
                            </a>
                        </div>
                    </div>

                    <div :id="'firstOpen' + i" class="panel-collapse collapse in">
                        <div class="accordion-inner" v-html="item.context"></div>
                    </div>
                </div>

                <div class="panel panel-success" v-if="i != 0">
                    <div class="panel-heading">
                        <div class="panel-title">
                            <a class="accordion-toggle collapsed" data-toggle="collapse"
                                data-parent="#accordionExpandAll" :href="'#firstOpen' + i">
                                <span v-if="!Settings.collapse_icon_position" class="closed">
                                    <i :class="Settings.expandIcon"></i>
                                </span>
                                <span v-if="!Settings.collapse_icon_position" class="opened">
                                    <i :class="Settings.collapseIcon"></i>
                                </span>
                                <span v-if="Settings.collapse_icon_position">
                                    <i :class="item.icon"></i>
                                </span>
                                {{ item.title }}
                                <span v-if="Settings.collapse_icon_position" class="closed float-right">
                                    <i :class="Settings.expandIcon"></i>
                                </span>
                                <span v-if="Settings.collapse_icon_position" class="opened float-right">
                                    <i :class="Settings.collapseIcon"></i>
                                </span>
                            </a>
                        </div>
                    </div>

                    <div :id="'firstOpen' + i" class="panel-collapse collapse">
                        <div class="accordion-inner" v-html="item.context"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import { CollapseTransition } from "vue2-transitions";
import  None  from "./none.vue";
// import None from '../None.vue';

export default {
  name: "ExpandAll",
  components: {
    // None
    nones:None,
    // CollapseTransition
  },
  props: {
    configForm: Object,
    Settings: Object,
    defaultItem: Array,
  },
  data() {
    return {};
  },
  methods: {
    getCollapseTitles(items) {
      let titles = [];
      items.forEach((element) => {
        titles.push(element.title);
      });
      return titles;
    },
  },
};
</script>

<style lang="scss" scoped>
.text-center {
  display: flex;
}
</style>

<style lang="scss" scoped>
.collapse.in {
    display: block!important;
}

// @import "~vue-material/dist/vue-material.min.css";
// @import "../assets/demo.css";
// @import "../assets/scss/material-kit.scss";
// @import "https://use.fontawesome.com/releases/v5.0.8/css/all.css";
// @import "https://fonts.googleapis.com/css?family=Roboto:300,400,500,700|Roboto+Slab:400,700|Material+Icons";
// @import "https://fonts.googleapis.com/icon?family=Material+Icons";
.reverse-row {
  flex-direction: row-reverse;
}
.icon-right {
  position: absolute;
  right: 30px;
}
</style>