import mtapi from './'

const getDocumentByVersionKey = (documentVersionKey) => {
    return mtapi.axios.post('/api/Document/_execute', {
        $type: 'Asi.Soa.Core.DataContracts.GenericExecuteRequest, Asi.Contracts',
        OperationName: 'FindByVersionId',
        EntityTypeName: 'Document',
        Parameters: {
            $type: 'System.Collections.ObjectModel.Collection`1[[System.Object, mscorlib]], mscorlib',
            $values: [{
                $type: 'System.String',
                $value: documentVersionKey
            }]
        },
        ParameterTypeName: {
            $type: 'System.Collections.ObjectModel.Collection`1[[System.String, mscorlib]], mscorlib',
            $values: [
                'System.String'
            ]
        },
        UseJson: false
    }).then(response => {
        if (response.data.$type.startsWith('Asi.Soa.Core.DataContracts.ServiceResponse') && response.data.Result.$type === 'Asi.Soa.Core.DataContracts.DocumentData, Asi.Contracts') {
            return response.data.Result
        } else {
            return response.data
        }
    })
}

const getDocumentByPath = (documentPath) => {
    return mtapi.axios.post('/api/Document/_execute', {
        $type: 'Asi.Soa.Core.DataContracts.GenericExecuteRequest, Asi.Contracts',
        OperationName: 'FindByPath',
        EntityTypeName: 'Document',
        Parameters: {
            $type: 'System.Collections.ObjectModel.Collection`1[[System.Object, mscorlib]], mscorlib',
            $values: [{
                $type: 'System.String',
                $value: documentPath
            }]
        },
        ParameterTypeName: {
            $type: 'System.Collections.ObjectModel.Collection`1[[System.String, mscorlib]], mscorlib',
            $values: [
                'System.String'
            ]
        },
        UseJson: false
    }).then(response => {
        if (response.data.$type.startsWith('Asi.Soa.Core.DataContracts.ServiceResponse') && response.data.Result.$type === 'Asi.Soa.Core.DataContracts.DocumentData, Asi.Contracts') {
            return response.data.Result
        } else {
            return response.data
        }
    })
}

const getIqaDefinition = (documentPathOrVersionKey) => {
    return mtapi.axios.post('/api/QueryDefinition/_execute', {
        $type: 'Asi.Soa.Core.DataContracts.GenericExecuteRequest, Asi.Contracts',
        OperationName: mtapi.isGuid(documentPathOrVersionKey) ? 'FindById' : 'FindByPath',
        EntityTypeName: 'QueryDefinition',
        Parameters: {
            $type: 'System.Collections.ObjectModel.Collection`1[[System.Object, mscorlib]], mscorlib',
            $values: [{
                $type: 'System.String',
                $value: documentPathOrVersionKey
            }]
        },
        ParameterTypeName: {
            $type: 'System.Collections.ObjectModel.Collection`1[[System.String, mscorlib]], mscorlib',
            $values: [
                System.String // eslint-disable-line
            ]
        },
        UseJson: false
    }).then(response => {
        if (response.data.$type.startsWith('Asi.Soa.Core.DataContracts.ServiceResponse') && response.data.Result.$type === 'Asi.Soa.Core.DataContracts.DocumentData, Asi.Contracts') {
            return response.data.Result
        } else {
            return response.data
        }
    })
}

const setIqaParamter = (documentPathorVersionKey, userData) => {
    return getIqaDefinition(documentPathorVersionKey).then(resp => {
        let params
        let first = true
        resp.Result.Parameters.$values.forEach(resp => {

            if (Object.keys(userData) && Object.keys(userData).length > 0) {

                if (userData.hasOwnProperty(resp.PropertyName)) {
                    params = params ? params + '&parameter=' + userData[resp.PropertyName] : '&parameter=' + userData[resp.PropertyName]
                } else {
                    params = params ? params + '&parameter=' : '&parameter='
                }





            } else {
                params = params ? params + '&parameter=' : '&parameter='
            }


        })

        return params
    })
}

const base64ToBlob = (base64, mime) => {
    mime = mime || ''
    var sliceSize = 1024
    var byteChars = atob(base64)
    var byteArrays = []
    for (var offset = 0, len = byteChars.length; offset < len; offset += sliceSize) {
        var slice = byteChars.slice(offset, offset + sliceSize)
        var byteNumbers = new Array(slice.length)
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i)
        }
        var byteArray = new Uint8Array(byteNumbers)
        byteArrays.push(byteArray)
    }
    return new Blob(byteArrays, { type: mime })
}

const documentTypeIdToMime = async(documentTypeId) => {
    const response = await mtapi.axios.get(`/api/FileTypeRef/${documentTypeId}`)
    const fileType = new mtapi.ImisEntity(response.data)
    return fileType.MimeType.split(',')[0]
}

const downloadBlobAsFile = (blob, filename) => {
    if (navigator.msSaveBlob) {
        navigator.msSaveOrOpenBlob(blob, filename)
    } else {
        var element = document.createElement('a')
        element.setAttribute('href', URL.createObjectURL(blob))
        element.setAttribute('download', filename)
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
    }
}

const getBlobAndFileNameFromDocumentSystem = async(documentPathOrVersionKey) => {
    let response
    if (mtapi.isGuid(documentPathOrVersionKey)) { response = await mtapi.getDocumentByVersionKey(documentPathOrVersionKey) } else { response = await mtapi.getDocumentByPath(documentPathOrVersionKey) }
    const base64Data = response.Data.$value
    const mime = await mtapi.documentTypeIdToMime(response.DocumentTypeId)
    const fileName = response.Name
    return { blob: mtapi.base64ToBlob(base64Data, mime), fileName: fileName }
}

const getBlobAndFileNameFromUDFile = (data) => {
    const xmlDoc = new DOMParser().parseFromString(atob(data), 'application/xml')
    const fileName = atob(xmlDoc.getElementsByTagName('UploadedFileName').length > 0 ? xmlDoc.getElementsByTagName('UploadedFileName')[0].textContent : '')
    const mime = atob(xmlDoc.getElementsByTagName('FileContentType')[0].textContent)
    const base64Data = xmlDoc.getElementsByTagName('File')[0].textContent
    return { blob: mtapi.base64ToBlob(base64Data, mime), fileName: fileName }
}

const fileUploadToUDFile = (file) => {
    const encodedFile = btoa(
        `<UploadedFile>
      <FileContentType>${btoa(file.type)}</FileContentType>
      <FileType>${btoa(file.name.split('.').pop().toUpperCase())}</FileType>
      <UploadedFileName>${btoa(file.name)}</UploadedFileName>
      <File>${btoa(file.data)}</File></UploadedFile>`)
    return encodedFile
}

const readAsBinaryStringSupported = (() => {
    return typeof FileReader !== 'undefined' &&
        typeof FileReader.prototype !== 'undefined' &&
        typeof FileReader.prototype.readAsBinaryString !== 'undefined'
})()

export default {
    getDocumentByVersionKey,
    getDocumentByPath,
    base64ToBlob,
    documentTypeIdToMime,
    downloadBlobAsFile,
    getBlobAndFileNameFromDocumentSystem,
    getBlobAndFileNameFromUDFile,
    readAsBinaryStringSupported,
    fileUploadToUDFile,
    getIqaDefinition,
    setIqaParamter
}